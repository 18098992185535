<template>
	<section v-if="user.name">
		<b-row class="match-height">
			<b-col cols="12" xl="9" lg="8" md="7">
				<b-card>
					<b-row>
						<!-- User Info: Left col -->
						<b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
							<!-- User Avatar & Action Buttons -->
							<div class="d-flex justify-content-start">
								<b-avatar :src="user.photo_url" size="104px" rounded />
								<div class="d-flex flex-column ml-1">
									<div class="mb-1">
										<h4 class="mb-0">
											{{ user.name }}
										</h4>
										<span class="card-text">{{ user.email }}</span>
									</div>
									<div class="d-flex align-items-center flex-wrap">
										<b-button variant="primary" @click="impersonate"> Impersonate </b-button>
										<b-button variant="secondary" class="ml-1" v-if="update_trial == false && user.plans[0] && user.plans[0].stripe_status == 'trialing'" @click="update_trial = true">Change Trial Expiry</b-button>
										<flat-pickr id="trial_date" @on-change="updateTrialDate" v-model="user.plans[0].trial_ends_at" v-if="update_trial" :config="{ minDate: 'today' }" class="ml-1" placeholder="Select Date" />
										<div v-if="loading" class="spinner-border spinner-border-sm ml-1" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</div>
								</div>
							</div>

							<!-- User Stats -->
							<div class="d-flex align-items-center mt-2">
								<div class="d-flex align-items-center mr-2">
									<b-avatar variant="light-primary" rounded>
										<feather-icon icon="DollarSignIcon" size="18" />
									</b-avatar>
									<div class="ml-1">
										<h5 class="mb-0">{{ currency }} {{ parseInt(user.all_sales) }}</h5>
										<small>Total Sales</small>
									</div>
								</div>

								<div class="d-flex align-items-center">
									<b-avatar variant="light-success" rounded>
										<feather-icon icon="TrendingUpIcon" size="18" />
									</b-avatar>
									<div class="ml-1">
										<h5 class="mb-0">{{ user.all_bookings }}</h5>
										<small>Total Bookings</small>
									</div>
								</div>
							</div>
						</b-col>

						<!-- Right Col: Table -->
						<b-col cols="12" xl="6">
							<table class="mt-2 mt-xl-0 w-100">
								<tr>
									<th class="pb-50">
										<feather-icon icon="HashIcon" class="mr-75" />
										<span class="font-weight-bold">User ID</span>
									</th>
									<td class="pb-50 text-capitalize">
										{{ user.id }}
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<feather-icon icon="GlobeIcon" class="mr-75" />
										<span class="font-weight-bold">Portal</span>
									</th>
									<td class="pb-50">
										<b-link :href="user.url" target="_blank">
											{{ user.url.replace("https://", "") }}
										</b-link>
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<feather-icon icon="DollarSignIcon" class="mr-75" />
										<span class="font-weight-bold">Currency</span>
									</th>
									<td class="pb-50 text-capitalize">
										{{ user.currency }}
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<feather-icon icon="FlagIcon" class="mr-75" />
										<span class="font-weight-bold">Country</span>
									</th>
									<td class="pb-50">
										{{ user.operator.operator_country.country }}
									</td>
								</tr>
								<tr>
									<th class="pb-50">
										<feather-icon icon="AwardIcon" class="mr-75" />
										<span class="font-weight-bold">Payment Gateway</span>
									</th>
									<td class="pb-50 text-capitalize" v-if="user.operator.payment_gateway">
										{{ user.operator.payment_gateway }}
									</td>
									<td class="pb-50 text-capitalize" v-else>N/A</td>
								</tr>
								<tr>
									<th class="pb-50">
										<feather-icon icon="PhoneCallIcon" class="mr-75" />
										<span class="font-weight-bold">Contact</span>
									</th>
									<td class="pb-50 text-capitalize" v-if="user.operator.operator_info">
										{{ user.operator.operator_info.contact_no }}
									</td>
									<td class="pb-50 text-capitalize" v-else>N/A</td>
								</tr>
							</table>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
			<b-col cols="12" md="5" xl="3" lg="4">
				<b-card no-body class="border-primary">
					<b-card-header class="d-flex justify-content-between align-items-center pt-80 pb-25">
						<h5 class="mb-0">Current Plan</h5>
						<div class="d-flex align-items-center">
							<b-badge variant="light-success mr-1" v-if="user.plans[0] && user.plans[0].stripe_status == 'trialing'"> Trial </b-badge>
							<b-badge variant="light-primary" v-if="user.current_billing_plan"> {{ plans[user.current_billing_plan] }} </b-badge>
							<b-badge variant="light-primary" v-else> Free </b-badge>
						</div>
					</b-card-header>

					<b-card-body class="pb-50">
						<ul class="list-unstyled my-1">
							<li>
								<small class="font-weight-bold">Stripe Customer ID</small>
								<div class="align-middle">{{ user.stripe_id }}</div>
							</li>
							<li class="my-25">
								<small class="font-weight-bold">Card</small>
								<div class="align-middle" v-if="user.card_brand">{{ user.card_brand.toUpperCase() }} - {{ user.card_last_four }}</div>
								<div class="align-middle" v-else>N/A</div>
							</li>
							<li class="my-25">
								<small class="font-weight-bold">Billing Address</small>
								<div class="align-middle" v-if="user.billing_address">{{ user.billing_address }}, {{ user.billing_city }}, {{ user.billing_state }}, {{ user.billing_country }} - {{ user.billing_zip }}</div>
								<div class="align-middle" v-else>N/A</div>
							</li>
						</ul>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-bookings :user-id="user.id" :currency="currency" />
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-activities :user-id="user.id" :currency="currency" />
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-coupons :user-id="user.id" :currency="currency" />
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-others :user-id="user.id" :currency="currency" />
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-logs :user-id="user.id" />
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" cols="12">
				<user-payment-logs :user-id="user.id" />
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardText, BAvatar, BLink, BBadge, BButton, BImg } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UserBookings from "./User/UserBookings.vue";
import UserActivities from "./User/UserActivities.vue";
import UserCoupons from "./User/UserCoupons.vue";
import UserOthers from "./User/UserOthers.vue";
import UserLogs from "./User/UserLogs.vue";
import UserPaymentLogs from "./User/UserPaymentLogs.vue";
import flatPickr from "vue-flatpickr-component";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		BCardText,
		BAvatar,
		BLink,
		BImg,
		BBadge,
		BButton,
		ToastificationContent,
		UserBookings,
		UserActivities,
		UserCoupons,
		UserOthers,
		UserLogs,
		UserPaymentLogs,
		flatPickr,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			id: null,
			plans: {},
			user: {},
			from_date: null,
			to_date: null,
			currency: "$",
			update_trial: false,
			loading: false,
		};
	},
	mounted() {
		this.plans = this.$plans();
		this.from_date = this.$moment().subtract(7, "d").unix();
		this.to_date = this.$moment().unix();
		this.id = this.$route.params.id;
		if (parseInt(this.id)) {
			this.$http.get(`/user/${this.id}/${this.from_date}/${this.to_date}`).then((res) => {
				this.user = res.data;
				this.currency = this.$currency(this.user.currency);
			});
		} else {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: "Notification",
					icon: "AlertCircleIcon",
					text: "That is not a valid User ID",
					variant: "danger",
				},
			});
		}
	},
	methods: {
		impersonate() {
			const key = localStorage.getItem("accessToken").split(".")[1];
			const redirect_url = `${this.user.url}/admin/user/${this.id}/impersonate/${key}`;
			window.open(redirect_url, "_blank");
		},
		updateTrialDate(dates, date) {
			this.loading = true;
			this.$http.post(`/user/${this.id}/trial/${date}`).then((res) => {
				this.loading = false;
				this.update_trial = false;
				this.$toast({
					component: ToastificationContent,
					props: {
						title: "Notification",
						icon: "CheckCircleIcon",
						text: "Trial end date changed successfully",
						variant: "success",
					},
				});
			});
		},
	},
};
</script>

<style>
small.font-weight-bold {
	font-weight: 600 !important;
}
#trial_date {
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	border: 1px solid #d8d6de;
}
</style>
